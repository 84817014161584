<template>
  <v-avatar icon :size="size" :style="style" class="outline" :tile="!rounded">
    <div v-if="badge" class="notification-count overline">
      {{ notificationCount }}
    </div>
    <span v-if="!isNameEmpty">
      {{ text | initials }}
    </span>
  </v-avatar>
</template>

<script>
export default {
  /**
   * The only avatar that you need! It uses Vuetify as a theme.
   * @displayName Avatar
   */
  name: 'Avatar',
  filters: {
    initials(value) {
      const parts = value.split(/[ -]/)
      let initials = ''
      for (let i = 0; i < parts.length; i += 1) {
        initials += parts[i].charAt(0)
      }
      if (initials.length > 3 && initials.search(/[A-Z]/) !== -1) {
        initials = initials.replace(/[a-z]+/g, '')
      }
      initials = initials.substr(0, 3).toUpperCase()
      return initials
    }
  },
  props: {
    /**
     * The size of avatar (sets width and height equally).
     */
    badge: {
      type: Boolean,
      default: false,
      required: false
    },
    /**
     * The image if text is not set or is empty string.
     */
    image: {
      type: String,
      default: ''
    },
    /**
     * The text if image is not set.
     */
    text: {
      type: String,
      default: ''
    },
    /**
     * The size of avatar (sets width and height equally).
     */
    size: {
      type: String,
      default: '50'
    },
    rounded: {
      type: Boolean,
      default: true
    },
    notificationCount: {
      type: Number,
      default: 0,
      required: false
    }
  },
  computed: {
    isNameEmpty() {
      return !this.text.replace(/^\s+/g, '').length
    },
    style() {
      const style = {
        border: 'initial',
        background: `transparent url('${this.image}') no-repeat scroll 0% 0% / ${this.size}px ${this.size}px content-box border-box`
      }

      return this.image ? style : ''
    }
  }
}
</script>

<style scoped>
.v-avatar.outline {
  border: 1px solid #96affc;
  color: #96affc;
}

.v-avatar.outline .notification-count {
  position: absolute;
  top: -4px;
  right: -4px;
  background: #fccdd3;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: white;
}
</style>
