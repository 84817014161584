<template>
  <v-toolbar
    :color="colors.header.bg"
    flat
    class="header"
    :class="{ expanded: isWindowExpanded }"
  >
    <slot v-if="!inUserList || !disableUserListToggle">
      <v-avatar v-if="imageUrl && !inUserList" size="50" class="mr-3">
        <v-img :src="imageUrl" :lazy-src="imageUrl">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-avatar>
      <v-btn
        v-if="!disableUserListToggle && !inUserList"
        :color="colors.header.text"
        text
        class="subtitle-2 text-capitalize"
        @click="toggleUserList()"
      >
        <v-icon>mdi-chevron-left</v-icon>{{ title }}
      </v-btn>
      <!-- <div v-else class="sc-header--title"> {{title}} </div> -->
    </slot>

    <v-spacer></v-spacer>
    <v-btn text icon :color="colors.header.text" @click="onClose">
      <v-icon>{{ isWindowExpanded ? 'mdi-close' : 'mdi-arrow-expand' }}</v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
export default {
  props: {
    imageUrl: {
      type: String,
      required: true
    },
    title: {
      type: String
    },
    onClose: {
      type: Function,
      required: true
    },
    isWindowExpanded: {
      type: Boolean,
      default: false
    },
    colors: {
      type: Object,
      required: true
    },
    disableUserListToggle: {
      type: Boolean,
      default: false
    },
    didToggleList: {
      type: Function,
      required: false
    },
    inUserList: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      // inUserList: false
    }
  },
  methods: {
    toggleUserList() {
      this.$emit('userList', this.inUserList)
      this.$emit('didToggleList')
    }
  }
}
</script>
<style scoped>
.header:not(.expanded) {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
</style>
