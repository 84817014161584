<template v-if="chats.length > 0">
  <v-card
    class="elevation-0"
    style="border-bottom-left-radius: 8px !important; border-bottom-right-radius: 8px !important;"
  >
    <v-list flat class="chat-list overflow-y-auto overflow-x-hidden">
      <v-subheader>
        {{ $vuetify.lang.t('$vuetify.messages.allConversations') }}
      </v-subheader>
      <!-- eslint-disable vue/no-use-v-if-with-v-for  -->
      <v-list-item
        v-for="(chat, index) in chats"
        v-if="!chat.isSold"
        :key="index"
        @click="$emit('onChatClick', chat)"
      >
        <v-list-item-avatar>
          <v-img
            :src="
              `https://firebasestorage.googleapis.com/v0/b/piletivahetus-develop.appspot.com/o/events%2Fjpg%2Fg_e_5h4234h${chat.event.globalEventId}h4234h.jpg?alt=media`
            "
            :lazy-src="
              `https://firebasestorage.googleapis.com/v0/b/piletivahetus-develop.appspot.com/o/events%2Fjpg%2Fg_e_5h4234h${chat.event.globalEventId}h4234h.jpg?alt=media`
            "
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title
            v-text="
              chat.senderId !== authorId
                ? `${chat.event.title} - ${chat.senderFirstName}`
                : chat.event.title
            "
          ></v-list-item-title>
        </v-list-item-content>

        <!-- TODO:: Enable if backend is ready for card payments -->
        <v-list-item-action>
          <v-chip v-if="chat.isSold" outlined color="success">{{
            chat.receiverId === authorId ? 'Sold' : 'Paid'
          }}</v-chip>
          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-show="
                  chat.receiverId !== authorId &&
                    chat.isSellable &&
                    !chat.isSold
                "
                text
                icon
                v-on="on"
                @click.stop="$emit('onChatActionClick', chat)"
              >
                <div color="error" class="notification-count overline">
                  <span slot="badge"></span>
                  <v-icon color="accent">credit_card</v-icon>
                </div>
              </v-btn>
            </template>
            <span>{{
              $vuetify.lang.t('$vuetify.messages.payForSelectedItem')
            }}</span>
          </v-tooltip> -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-show="!chat.isSold && chat.receiverId === authorId"
                text
                icon
                @click.stop="$emit('onChatActionClick', chat)"
                v-on="on"
              >
                <v-icon color="accent">mdi-check</v-icon>
              </v-btn>
            </template>
            <span>{{ $vuetify.lang.t('$vuetify.posts.markAsSold') }}</span>
          </v-tooltip>
        </v-list-item-action>
        <v-list-item-icon>
          <v-icon color="error" :class="count(chat.id) > 0 ? 'shake' : ''">
            {{ count(chat.id) > 0 ? 'mdi-bell-alert' : 'mdi-bell-outline' }}
          </v-icon>
          <span class="error--text">{{ count(chat.id) }}</span>
        </v-list-item-icon>
      </v-list-item>
      <slot v-if="!chats || chats.length <= 0" name="chats-empty" />
    </v-list>
  </v-card>
</template>
<script>
import { mapState } from 'vuex'

export default {
  props: {
    chats: {
      type: Array,
      required: true
    },
    onChatClick: {
      type: Function,
      required: false
    },
    authorId: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState('chats', ['unreadMessagesCount'])
  },
  methods: {
    count(chatId) {
      const chatInUnreadMessagesCount = this.unreadMessagesCount.find(
        chat => chat.id === chatId
      )
      if (chatInUnreadMessagesCount) {
        return chatInUnreadMessagesCount.count
      }
      return 0
    }
  }
}
</script>
<style scoped>
.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
  animation-delay: 0.6s;
}
.v-card {
  height: 100%;
}
.chat-list {
  height: 100%;
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0) rotate(-4deg);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0) rotate(3deg);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0) rotate(-3deg);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0) rotate(3deg);
  }
}
.notification-count span {
  position: absolute;
  background: #fccdd3;
  width: 10px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: white;
}
</style>
