<template>
  <div
    class="sc-chat-window primary-shadow"
    :class="{ opened: isOpen, closed: !isOpen, expanded: chatExpanded }"
  >
    <Header
      :title="title"
      :image-url="titleImageUrl"
      :on-close="onClose"
      :colors="colors"
      :disable-user-list-toggle="disableUserListToggle"
      :in-user-list="showUserList"
      :is-window-expanded="chatExpanded"
      @userList="handleUserListToggle"
      @didToggleList="$emit('didToggleList')"
    >
      <template>
        <slot name="header"> </slot>
      </template>
    </Header>
    <!-- <UserList v-if="showUserList" :participants="participants" /> -->
    <ChatsList
      v-if="disableUserListToggle || showUserList"
      :chats="chats"
      :author-id="authorId"
      @onChatClick="didClickChat"
      @onChatActionClick="didClickChatAction"
    />
    <MessageList
      v-if="!showUserList && !disableUserListToggle"
      :messages="messages"
      :author-id="authorId"
      :participants="participants"
      :show-typing-indicator="showTypingIndicator"
      :colors="colors"
      :always-scroll-to-bottom="alwaysScrollToBottom"
      :message-styling="messageStyling"
      @scrollToTop="$emit('scrollToTop')"
      @remove="$emit('remove', $event)"
    >
      <template v-slot:user-avatar="scopedProps">
        <slot
          name="user-avatar"
          :user="scopedProps.user"
          :message="scopedProps.message"
        >
        </slot>
      </template>
      <template v-slot:text-message-body="scopedProps">
        <slot
          name="text-message-body"
          :message="scopedProps.message"
          :messageText="scopedProps.messageText"
          :messageColors="scopedProps.messageColors"
          :me="scopedProps.me"
        >
        </slot>
      </template>
      <template v-slot:system-message-body="scopedProps">
        <slot name="system-message-body" :message="scopedProps.message"> </slot>
      </template>
      <template v-slot:text-message-toolbox="scopedProps">
        <slot
          name="text-message-toolbox"
          :message="scopedProps.message"
          :me="scopedProps.me"
        >
        </slot>
      </template>
    </MessageList>
    <UserInput
      v-if="!showUserList && !disableUserListToggle"
      :show-emoji="showEmoji"
      :on-submit="onUserInputSubmit"
      :suggestions="getSuggestions()"
      :show-file="showFile"
      :placeholder="placeholder"
      :colors="colors"
      @onType="$emit('onType')"
      @edit="$emit('edit', $event)"
    />
  </div>
</template>

<script>
import Header from '@/components/Chat/Header'
import MessageList from '@/components/Chat/components/MessageList'
import UserInput from '@/components/Chat/UserInput'
// import UserList from './UserList.vue'
import ChatsList from './ChatsList.vue'

export default {
  components: {
    Header,
    MessageList,
    UserInput,
    // UserList,
    ChatsList
  },
  props: {
    showEmoji: {
      type: Boolean,
      default: false
    },
    showFile: {
      type: Boolean,
      default: false
    },
    participants: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    titleImageUrl: {
      type: String,
      default: ''
    },
    onUserInputSubmit: {
      type: Function,
      required: true
    },
    messageList: {
      type: Array,
      default: () => []
    },
    isOpen: {
      type: Boolean,
      default: () => false
    },
    placeholder: {
      type: String,
      default: 'Write a reply'
    },
    showTypingIndicator: {
      type: String,
      required: true
    },
    colors: {
      type: Object,
      required: true
    },
    alwaysScrollToBottom: {
      type: Boolean,
      required: true
    },
    messageStyling: {
      type: Boolean,
      required: true
    },
    disableUserListToggle: {
      type: Boolean,
      default: false
    },
    authorId: {
      type: String,
      required: false
    },
    chats: {
      type: Array,
      required: false
    },
    onChatClick: {
      type: Function,
      required: false
    },
    didToggleList: {
      type: Function,
      required: false
    },
    paymentFormDialog: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      showUserList: false,
      chatExpanded: false
    }
  },
  computed: {
    messages() {
      const messages = this.messageList

      return messages
    }
  },
  methods: {
    didClickChat(item) {
      this.showUserList = true
      this.$emit('onChatClick', item)
      this.handleUserListToggle()
    },
    didClickChatAction(item) {
      this.$emit('onChatActionClick', item)
    },
    handleUserListToggle() {
      this.showUserList = !this.showUserList
    },
    getSuggestions() {
      return this.messages.length > 0
        ? this.messages[this.messages.length - 1].suggestions
        : []
    },
    onClose() {
      this.chatExpanded = !this.chatExpanded
    }
  }
}
</script>
<style scoped>
.sc-chat-window {
  width: 370px;
  height: 60vh;
  max-height: 590px;
  position: fixed;
  right: 25px;
  bottom: 100px;
  box-sizing: border-box;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.3s ease-in-out;
  border-radius: 16px !important;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.sc-chat-window.expanded {
  height: 100vh !important;
  max-height: 100vh !important;
  width: 100vw;
  left: 0;
  top: 0;
  position: fixed !important;
}
.sc-chat-window.closed {
  opacity: 0;
  visibility: hidden;
  bottom: 90px;
}

.sc-message--me {
  text-align: right;
}
.sc-message--them {
  text-align: left;
}

@media (max-width: 450px) {
  .sc-chat-window {
    width: 100%;
    height: 60vh;
    max-height: 100%;
    right: 0px;
    bottom: 0px;
    border-radius: 0px;
  }
  .sc-chat-window {
    transition: 0.1s ease-in-out;
  }
  .sc-chat-window.closed {
    bottom: 0px;
  }
}
</style>
